const config = {
  apiGateway: {
    REGION: 'us-east-1',
    PHY_LOCATION_APIGATEWAY_URL: 'https://io0tvnhn9h.execute-api.us-east-1.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://aew0khuos2.execute-api.us-east-1.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://ubfd5ongb1.execute-api.us-east-1.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.12.1',
    HOSTNAME: 'https://locations.riotinto.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.riotinto.forwoodsafety.com',
    WEBSOCKET: 'wss://w0ii35o49j.execute-api.us-east-1.amazonaws.com/prod'
  },
};

export default config;